import { Box, Text, Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, useDisclosure } from "@chakra-ui/react"

import InteractiveDemoModal from "./InteractiveDemoModal"
import { VideoModal } from "./VideoModal"
import { HubspotContactFormWidget } from "./HubspotContactFormWidget"

export enum CTAButtonAction {
  InteractiveDemo,
  BookDemo,
  PlayVideo,
}

const CTAButton = ({ label = "Get a Demo", action = CTAButtonAction.InteractiveDemo, muted = false }: { label?: string; action?: CTAButtonAction; muted?: boolean }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <Button
        color="white"
        bg={muted ? "var(--website-color-button-light)" : "var(--website-color-highlight)"}
        borderRadius={muted ? "20px" : "50px"}
        border={muted ? "1px solid var(--website-color-button-border)" : undefined}
        _hover={{
          bg: muted ? "var(--secondary)" : "var(--website-color-highlight)",
          color: muted ? "var(--secondary5)" : undefined,
        }}
        onClick={onOpen}
        fontSize="18px"
        fontWeight="bold"
        px="24px"
      >
        {label}
      </Button>

      {isOpen &&
        {
          [CTAButtonAction.InteractiveDemo]: <InteractiveDemoModal onClose={onClose} />,
          [CTAButtonAction.BookDemo]: (
            <Modal isOpen={true} onClose={onClose} size="lg">
              <ModalOverlay />
              <ModalContent background="transparent" boxShadow="none">
                <ModalCloseButton fontSize="28px" color="white" size="24px" marginRight="44px" marginTop="24px" />
                <ModalBody paddingBottom="24px" paddingTop="0px">
                  <Box borderRadius="8px" background="var(--website-color-highlight)" fontWeight="700" fontFamily="var(--landing-page-font)" p="40px">
                    <Text fontSize={["40px", "44px"]} color="white" lineHeight="48px" mb="16px">
                      Start the Conversation
                    </Text>
                    <Text fontSize="16px" fontWeight="400" color="white" mb="42px">
                      Ready to explore how Integral can help you maximize your data strategy? Schedule time with Integral&rsquo;s CEO, Shubh Sinha, below.
                    </Text>
                    <Box mx="20px">
                      <HubspotContactFormWidget frameless />
                    </Box>
                  </Box>
                </ModalBody>
              </ModalContent>
            </Modal>
          ),
          [CTAButtonAction.PlayVideo]: <VideoModal onClose={onClose} />,
        }[action]}
    </>
  )
}

export default CTAButton
